<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <AError
      v-if="api.isError"
      :api="api"
      :callbackReset="() => (api.isError = false)"
    ></AError>
    <v-card
      :loading="api.isLoading"
      elevation="0"
      class="mx-auto"
      max-width="800"
      outlined
      min-height="200"
    >
      <v-card-title>{{ $t("model.name.finishers") }}</v-card-title>
      <div class="pa-2">
        <div class="d-flex pb-6 text-right">
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            v-if="auth.Session && auth"
            class="mr-2"
            dark
            @click="generateReport"
            :loading="generatingPDF"
          >
            下载完赛者PDF
          </v-btn>
          <div>
            <JsonExcel
              name="finishers.xls"
              worksheet="Hamochi"
              :data="printData"
            >
              <v-btn color="primary" dark>
                {{ $t("action.download_excel") }}
              </v-btn>
            </JsonExcel>
          </div>
        </div>
        <div>
          {{ $t("model.prop.total") }} {{ finishers.length }}
          {{ $t("model.name.students") }}
        </div>
        <v-simple-table style="border: 1px solid rgba(0, 0, 0, 0.12)">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">{{ $t("model.prop.no") }}</th>
                <th class="text-left" colspan="2">
                  <span>{{ $t("model.name.student") }}</span>
                </th>
                <th class="text-left">{{ $t("model.name.classroom") }}</th>
                <!-- <th class="text-center">{{ $t("model.name.attempts") }}</th> -->
                <th class="text-right">{{ $t("model.name.submissions") }}</th>
                <th class="text-right">{{ $t("model.prop.stars") }}</th>
                <th class="text-right">{{ $t("model.prop.score") }}</th>
              </tr>
            </thead>
            <tbody class="table">
              <tr class="text-center" v-if="finishers.length == 0">
                <td colspan="12" class="grey--text">
                  {{ $t("api.response.no_data") }}
                </td>
              </tr>
              <tr v-for="(item, index) in finishers" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  <v-lazy>
                    <AAvatar v-if="item.avatar" :avatar="item.avatar"></AAvatar>
                    <div v-else>
                      <v-img
                        :max-height="40"
                        :max-width="40"
                        :src="require('@/assets/CS0040_default_F0.png')"
                      ></v-img>
                    </div>
                  </v-lazy>
                </td>
                <td class="text-uppercase">
                  <span>{{ item.name }}</span>
                </td>
                <td class="text-left text-uppercase">
                  {{ item.classroomName }}
                </td>
                <td class="text-right">{{ item.totalSubmission }}</td>
                <td class="text-right">
                  <v-chip x-small color="yellow">{{ item.totalStar }} ★</v-chip>
                </td>
                <td class="text-right">{{ item.totalScore }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <vue-html2pdf
          :show-layout="false"
          :float-layout="true"
          :enable-download="true"
          :preview-modal="false"
          :paginate-elements-by-height="1400"
          filename="Finisher"
          :pdf-quality="2"
          :manual-pagination="false"
          pdf-format="a4"
          pdf-orientation="portrait"
          pdf-content-width="800px"
          @progress="onProgress($event)"
          @hasStartedGeneration="hasStartedGeneration()"
          @hasGenerated="hasGenerated($event)"
          ref="html2Pdf"
        >
          <section slot="pdf-content">
            <section class="pdf-item pdf-content">
              <h4 style="padding-left: 20px !important; padding-top: 10px; padding-right: 20px !important">
            {{ institution.Institution.name }} - {{event.Series.name}} {{ event.name }} {{ $t("model.name.finishers") }} ({{$moment().format('YYYY-MM-DD')}})
              </h4>
              <div style="padding-left: 20px !important; padding-top: 8px">
                {{ $t("view.PageMainEventScoreboard.event_pdf_total_finisher") }}
                {{ finishers.length }}
              </div>
            </section>

            <section
              v-for="i in Math.ceil(pdfData.length / 20)"
              :key="i"
              class="pdf-item pdf-content"
            >
              <v-simple-table style="border: 1px solid rgba(0, 0, 0, 0.12); padding: 5px !important; margin: 3px !important;margin-right: 3px !important;">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-head">{{ $t("model.prop.no") }}</th>
                      <th class="text-head">
                        <span class="text-head">{{
                          $t("model.name.student")
                        }}</span>
                      </th>
                      <th class="text-head">
                        {{ $t("model.name.classroom") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody class="table">
                    <tr class="text-center" v-if="pdfData.length == 0">
                      <td colspan="12" class="grey--text">
                        {{ $t("api.response.no_data") }}
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in pdfData.slice(
                        (i - 1) * 20,
                        (i - 1) * 20 + 20
                      )"
                      :key="index + i"
                    >
                      <td>{{ index + 1 + (i - 1) * 20 }}</td>
                      <td class="text-uppercase text-left">
                        {{ item.name }}
                      </td>
                      <td class="text-left text-uppercase">
                        {{ item.classroomName }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </section>
          </section>
        </vue-html2pdf>
      </div>
    </v-card>
  </v-container>
</template>

<script>
// import Event from '@/components/Event/Event'
import { mapState } from "vuex";
import JsonExcel from "vue-json-excel";
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    JsonExcel,
    VueHtml2pdf,
    // Event,
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  data: () => ({
    printData: [],
    pdfData: [],
    finisherCountBySchoolYear: [],
    topStudentsBySchoolYear: {},
    generatingPDF: false,
    event: {},
    institution:{},
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    finishers: [],
  }),
  created() {
    //BOC:[breadcrumbs]
    this.breadcrumbs.push({
      text: this.$t("route.PageMainHome"),
      to: { name: "PageMainHome" },
      exact: true,
    });
    this.breadcrumbs.push({
      text: this.$t("route.PageMainEvent"),
      to: { name: "PageMainEvent" },
      exact: true,
    });
    if (this.$route.query.pw) {
      this.breadcrumbs.push({
        text: this.$route.params.eventCode,
        to: {
          name: "PageMainEventScoreboard",
          params: { code: this.$route.params.eventCode },
          query: { pw: this.$route.query.pw },
        },
        exact: true,
      });
    } else {
      this.breadcrumbs.push({
        text: this.$route.params.eventCode,
        to: {
          name: "PageMainEventScoreboard",
          params: { code: this.$route.params.eventCode },
        },
        exact: true,
      });
    }
    this.breadcrumbs.push({
      text: this.$t("model.name.finishers"),
      to: { name: "PageMainEventFinisher" },
      exact: true,
    });
    //EOC

    this.api.url = `${this.$api.servers.event}/api/v1/en/main/event/${this.$route.params.eventCode}/finisher/${this.$route.params.schoolCode}`;
    this.api.method = "GET";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };

    this.api.callbackSuccess = (resp) => {
      this.finishers = resp.Finisher;
      this.event = resp.Event;
      this.institution = resp.Institution
      this.pdfData = this.$_.orderBy(
        this.finishers,
        ["classroomName", "name"],
        ["asc", "asc"]
      );

      var printData = this.finishers.map((obj) => {
        return {
          Name: obj.name,
          Classroom: obj.classroomName,
          "Total Submission": obj.totalSubmission,
          "Total Star": obj.totalStar,
          "Total Score": obj.totalScore,
        };
      });
      printData = this.$_.sortBy(printData, ["Classroom", "Name"]);
      this.printData = printData.map((obj, index) => {
        return {
          No: index + 1,
          ...obj,
        };
      });
      this.api.isLoading = false;
    };
  },
  methods: {
    generateReport() {
      this.generatingPDF = true;
      this.$refs.html2Pdf.generatePdf();
      setTimeout(() => {
        this.generatingPDF = false;
      }, 1000);
    },
  },
  mounted() {
    this.$api.fetch(this.api);
  },
};
</script>
<style scoped>
.text-head {
  text-align: left !important;
}
</style>